module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-tailwind","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#19538E","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4dc2b816281fe30383d787a8c3a4ca6e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.hoppings.co.uk/graphql","schema":{"timeout":900000,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100},"html":{"imageMaxWidth":1920,"useGatsbyImage":true,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true},"type":{"Page":{"exclude":true},"Post":{"exclude":true},"Menu":{"exclude":true},"Comment":{"exclude":true},"MenuItem":{"exclude":true},"User":{"exclude":true},"Tag":{"exclude":true},"UserRole":{"exclude":true},"Taxonomy":{"exclude":true},"ContentType":{"exclude":true},"PostFormat":{"exclude":true},"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4","pdf"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-24566228-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
